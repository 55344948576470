import React from "react";
import PropTypes from "prop-types";
import styles from "./Pembuka.module.scss";
import { Box, Container } from "@mui/material";
import { ButtonMain } from "../../Form";
import { ReactComponent as Flower } from "../../../assets/bunga/flower_1.svg";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

function Component({ handleRSVP, params }) {
  const { name: guestName, partner: guestPartner } = params;
  const { title } = useSelector((state) => state.details);

  const container = {
    visible: { transition: { staggerChildren: 0.15 } },
  };
  const fadeUp = {
    hidden: { y: 100, opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 1 } },
  };
  const zoomIn = {
    hidden: { scale: 0, opacity: 0 },
    visible: { scale: 1, opacity: 1, transition: { duration: 1 } },
  };

  return (
    <>
      <motion.div
        className={styles.pembuka__wrapper}
        initial={{ y: "-100vh" }}
        animate={{ y: 0 }}
        transition={{ duration: 1 }}
      >
        <Container maxWidth="lg" style={{ height: "100%" }}>
          <motion.div
            className={
              guestName
                ? styles.pembuka__container
                : styles.pembuka__container__nonrsvp
            }
            initial="hidden"
            whileInView="visible"
            variants={container}
          >
            <Box className={styles._title}>
              <Box className={styles._title__content}>
                <motion.div variants={zoomIn}>
                  <Flower className={styles._bunga} />
                </motion.div>
                <motion.span
                  className={`${styles._title__body} text-body`}
                  variants={fadeUp}
                >
                  Pernikahan
                </motion.span>
                <motion.span
                  className={`${styles._title__name} header-3 mb-8`}
                  variants={fadeUp}
                >
                  {title}
                </motion.span>
                {!guestName ? (
                  <motion.span
                    className={`${styles._title__date} text-body`}
                    variants={fadeUp}
                  >
                    05 Mei 2023
                  </motion.span>
                ) : null}
              </Box>
            </Box>

            {guestName ? (
              <Box className={styles._invited__container}>
                <motion.div className="text-body" variants={fadeUp}>
                  Hello,
                </motion.div>
                <Box className={styles.pembuka__invited__content}>
                  <motion.div
                    className={styles.pembuka__invited__name}
                    variants={fadeUp}
                  >
                    {guestName}
                  </motion.div>
                  {guestPartner && (
                    <motion.div variants={fadeUp}>
                      dan {guestPartner}
                    </motion.div>
                  )}
                </Box>
                <motion.div className="text-body" variants={fadeUp}>
                  Kami mengundang Anda untuk merayakan pernikahan kami.
                </motion.div>
              </Box>
            ) : null}

            <motion.div variants={fadeUp}>
              <ButtonMain onClick={handleRSVP}>Buka Undangan</ButtonMain>
            </motion.div>
          </motion.div>
        </Container>
      </motion.div>
    </>
  );
}

Component.propTypes = {
  params: PropTypes.object,
};

export default Component;
