import { lazy, Suspense, useEffect, useState } from "react";
import "./App.scss";
import { SnackbarContainer } from "./context/Snackbar/SnackbarContext";
import { SnackbarDialog } from "./context/Snackbar/SnackbarDialog";
import { Loading, ScrollToTop } from "./components/Layout";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getDetail, getDimension } from "./store/actions";

const Main = lazy(() => import("./pages/Main"));
const RSVPList = lazy(() => import("./pages/RSVP/RSVPList"));

function App() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleResize = async () => {
    await dispatch(getDimension());
  };

  useEffect(() => {
    handleResize();
    dispatch(getDetail(setLoading));

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <header>
        <h1 className="hidden">Iska & Arif</h1>
      </header>

      <Router>
        <ScrollToTop />
        <SnackbarContainer>
          <Switch>
            <Route exact path="/">
              <Suspense fallback={<Loading fullscreen />}>
                {!loading ? <Main /> : <Loading fullscreen />}
              </Suspense>
            </Route>
            <Route exact path="/rsvp-list">
              <Suspense fallback={<Loading fullscreen />}>
                <RSVPList />
              </Suspense>
            </Route>
          </Switch>
          <SnackbarDialog />
        </SnackbarContainer>
      </Router>
    </>
  );
}

export default App;
